import React, {Component} from "react";
import moment from "moment";
import ContactService from "../../services/ContactService";
import withAuth from "../high-order-components/Auth-HOC";
import {setLoading} from "../../store/actions/creators/appActionCreator";
import {connect} from "react-redux";
import {message} from "../ui-kit/MessageGate";
import i18nRepo from "../../i18n-repo/i18nRepo";

class Contact extends Component
{
  constructor (props)
  {
    super(props);

    this.service = new ContactService();

    this.state = {
      documents: []
    }
  }

  async componentDidMount ()
  {
    try
    {
      this.props.setLoading(true);

      const documents = (await this.service.get({}, this.props.language)).documents


      this.setState((previousState, props) => ({
        documents: documents
      }))
    }
    catch (error)
    {
      if (error.statusCode === 401)
      {
        this.props.authActions.logout();
      }

      message({
                type: "error",
                content: i18nRepo.getError("there_is_a_problem_with_getting_your_contact_data")
              });
    }
    finally
    {
      this.props.setLoading(false);
    }
  }

  render ()
  {
    return (
      <div className={"initial-page contact"}>
        <div className={"contact__header"}>
          {
            i18nRepo.getWord("contact")
          }
        </div>

        <div className={"contact__content-container"}>
          {
            this.state.documents.map((data, index) => (
              <div
                key={index}
                className={"contact__content"}
              >
                <div className={"contact__content-top"}>
                  <div className={"contact__content-client-container"}>
                    <div className={"contact__content-client-data"}>
                      <div className={"contact__content-client-data-label"}>
                        {
                          i18nRepo.getWord("name") + ":"
                        }
                      </div>

                      <div className={"contact__content-client-data-value"}>
                        {
                          data.firstName + " " + data.lastName
                        }
                      </div>
                    </div>

                    <div className={"contact__content-client-data"}>
                      <div className={"contact__content-client-data-label"}>
                        {
                          i18nRepo.getWord("email") + ":"
                        }
                      </div>

                      <div className={"contact__content-client-data-value"}>
                        {
                          data.email
                        }
                      </div>
                    </div>

                    <div className={"contact__content-client-data"}>
                      <div className={"contact__content-client-data-label"}>
                        {
                          i18nRepo.getWord("phone") + ":"
                        }
                      </div>

                      <div className={"contact__content-client-data-value"}>
                        {
                          data.phone
                        }
                      </div>
                    </div>
                  </div>

                  <div className={"contact__content-date"}>
                    {
                      moment(data.createdAt).format("DD-MM-YYYY")
                    }
                  </div>

                </div>

                <div className={"contact__content-bottom"}>
                  <div className={"contact__content-subject"}>
                    <div className={"contact__content-label"}>
                      {
                        i18nRepo.getWord("subject") + " :"
                      }
                    </div>

                    {
                      data.subject
                    }
                  </div>

                  <div className={"contact__content-content"}>
                    <div className={"contact__content-label"}>
                      {
                        i18nRepo.getWord("content") + " :"
                      }
                    </div>

                    {
                      data.content
                    }
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading))
});

export default withAuth(connect(null, mapDispatchToProps)(Contact));