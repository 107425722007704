import React, {Component} from "react";

import i18nRepo from "../../i18n-repo/i18nRepo";

import withAuth from "../high-order-components/Auth-HOC";

const OPTIONS = {
  "blog": "blog",
  "blog-contact": "blog-contact",
  "contact": "contact",
  "logout": "logout"
}

class LandingPage extends Component
{
  onClickOption = (type) =>
  {
    switch (type)
    {
      case OPTIONS["blog"]:
        this.props.history.push("/blog");
        return;

      case OPTIONS["blog-contact"]:
        this.props.history.push("/blog-contact");
        return;

      case OPTIONS["contact"]:
        this.props.history.push("/contact");
        return;

      case OPTIONS["logout"]:
        this.props.authActions.logout();
        return;

      default:
        // noop
    }
  }

  render ()
  {
    return (
      <div className={"initial-page landing-page"}>
        <div className={"landing-page__content-wrapper"}>
          <div className={"landing-page__options-container"}>
            <div
              className={"landing-page__option-wrapper"}
              onClick={() => this.onClickOption(OPTIONS["blog"])}
            >
              <i className="far fa-file-alt landing-page__option-icon" />

              <div className={"landing-page__option-text"}>
                {
                  i18nRepo.getWord("blog")
                }
              </div>
            </div>

            <div
              className={"landing-page__option-wrapper"}
              onClick={() => this.onClickOption(OPTIONS["blog-contact"])}
            >
              <i className="far fa-comments landing-page__option-icon" />

              <div className={"landing-page__option-text"}>
                {
                  i18nRepo.getWord("blog_contact")
                }
              </div>
            </div>

            <div
              className={"landing-page__option-wrapper"}
              onClick={() => this.onClickOption(OPTIONS["contact"])}
            >
              <i className="fas fa-phone landing-page__option-icon" />

              <div className={"landing-page__option-text"}>
                {
                  i18nRepo.getWord("contact")
                }
              </div>
            </div>
          </div>

          <div
            className={"landing-page__logout-wrapper"}
            onClick={() => this.onClickOption(OPTIONS["logout"])}
          >
            <i className="fas fa-sign-out-alt landing-page__logout-icon" />

            <div className={"landing-page__logout-text"}>
              {
                i18nRepo.getWord("logout")
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withAuth(LandingPage);