import React, {Component} from "react";
import _ from "lodash";

import CommonHelper from "../../libs/CommonHelper";
import OuterClickHandler from "../elements/OuterClickHandler";
import Arrow from "./Arrow";

class Select extends Component
{
  constructor (props)
  {
    super(props);

    this.state = {
      currentDisplayValue: null,
      currentValue: null,
      valueToSet: null,

      optionsVisible: false
    };
  }

  componentDidMount ()
  {
    if (CommonHelper.isExist(this.state.valueToSet))
    {
      this.setValue(this.state.valueToSet);
    }

    this.setState((previousState, props) => ({
      valueToSet: null
    }));
  }

  shouldComponentUpdate (nextProps, nextState, nextContext)
  {
    if (!_.isEqual(this.props.value, nextProps.value))
    {
      this.setValue(nextProps.value);
    }

    if (!_.isEqual(this.props.options, nextProps.options))
    {
      this.setValue(nextProps.value);
    }

    return true;
  }

  getValue = () =>
  {
    return this.state.currentValue;
  };

  setValue = (value) =>
  {
    if (!CommonHelper.isInitialized(value))
    {
      this.setState((previousState, props) => ({
        currentValue: null,
        valueToSet: null
      }));
    }

    if (!CommonHelper.isExist(this.props.options))
    {
      this.setState((previousState, props) => ({
        valueToSet: value
      }));

      return;
    }

    for (let i = 0; i < this.props.options.length; i++)
    {
      const currentOption = this.props.options[i];

      if (_.isEqual(value, currentOption.value))
      {
        this.setState((previousState, props) => ({
          currentValue: currentOption.value,
          currentDisplayValue: currentOption.displayName
        }));

        break;
      }
    }
  };

  onSelectOption = (option) =>
  {
    if (CommonHelper.isExist(this.props.onSelect))
    {
      this.props.onSelect(option.value);
    }

    this.setState((previousState, props) => ({
      currentValue: option.value,
      currentDisplayValue: option.displayName
    }));
  };

  onClickSwapVisible = () =>
  {
    this.setState((previousState, props) => ({
      optionsVisible: !previousState.optionsVisible
    }));
  };

  onClickOut = () =>
  {
    if (this.state.optionsVisible)
    {
      this.setState((previousState, props) => ({
        optionsVisible: false
      }));
    }
  };

  render ()
  {
    const prepareOptions = () =>
    {
      const result = [];

      for (let i = 0; i < this.props.options.length; i++)
      {
        const currentOption = this.props.options[i];

        result.push(
          <div
            className={"select__option"}
            key={currentOption.key}
            onClick={() => this.onSelectOption(currentOption)}
          >
            {
              currentOption.displayName
            }
          </div>
        );
      }

      return result;
    };

    return (
      <OuterClickHandler
        className={"select__outer-wrapper"}
        key={"select-out-click-handler"}
        onClickOut={this.onClickOut}
      >
        <div
          onClick={this.onClickSwapVisible}
          className={"select " + this.props.className}
        >
          <div className={"select__text"}>
            {
              CommonHelper.isExist(this.state.currentValue)
              ? this.state.currentDisplayValue
              : ""
            }
          </div>


          <div className={"select__arrow-wrapper"}>
            <Arrow
              size={"0.3rem"}
              position={this.state.optionsVisible
                        ? "up"
                        : "down"}
            />
          </div>

          <div className={"select__options-container"}>
            {
              this.state.optionsVisible
              &&
              (
                <div className={"select__options-wrapper"}>
                  {
                    prepareOptions()
                  }
                </div>
              )
            }
          </div>

        </div>
      </OuterClickHandler>
    );
  }
}

export default Select;