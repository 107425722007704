import React, {Component} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import isJWT from "validator/es/lib/isJWT";

import CommonHelper from "../../libs/CommonHelper";

import {setUser} from "../../store/actions/creators/appActionCreator";

function withAuth (WrappedComponent)
{
  const mapStateToProps = (state) => ({
    language: state.appReducer.language,
    user: state.appReducer.user
  });

  const mapDispatchToProps = (dispatch) => ({
    setUser: (isMobile) => dispatch(setUser(isMobile))
  });

  return withRouter(connect(mapStateToProps, mapDispatchToProps)
                    (
                      class extends Component
                      {
                        authenticate = () =>
                        {
                          const token = localStorage.getItem("token");

                          return CommonHelper.isExist(token) && isJWT(token);
                        };

                        logout = () =>
                        {
                          localStorage.removeItem("token");
                          this.props.setUser(null);

                          this.props.history.push("/login");
                        };

                        render ()
                        {
                          return (
                            <WrappedComponent
                              {...this.props}
                              authActions={{
                                logout: this.logout,
                                authenticate: this.authenticate
                              }}
                            />
                          );
                        }
                      }
                    ));
}

export default withAuth;