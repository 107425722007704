import React, {Component} from "react";
import {connect} from "react-redux";

import {setLoading} from "../../store/actions/creators/appActionCreator";

import i18nRepo from "../../i18n-repo/i18nRepo";

import AuthService from "../../services/AuthService";

import withAuth from "../high-order-components/Auth-HOC";

import Form from "../ui-kit/Form";
import Button from "../ui-kit/Button";
import Input from "../ui-kit/Input";

import {SglIcon} from "../../assets/index";
import MessageGate, {message} from "../ui-kit/MessageGate";

class Login extends Component
{
  constructor (props)
  {
    super(props);

    this.authService = new AuthService();

    this.formRef = React.createRef();
  }

  componentDidMount ()
  {
    if (this.props.authActions.authenticate())
    {
      this.props.history.replace("/");
    }
  }

  onSubmit = async (values) =>
  {
    try
    {
      this.props.setLoading(true);

      const loginResult = await this.authService.login(values, this.props.language);
      const token = loginResult.token;
      const user = loginResult.account;
      this.props.setUser(user);
      localStorage.setItem("token", token);

      this.props.history.push("/");
    }
    catch (error)
    {
      message({
                type: "success",
                content: "Failed"
              });
    }
    finally
    {
      this.props.setLoading(false);
    }
  };

  onClickLogin = () =>
  {
    this.formRef.current.submit();
  };

  render ()
  {
    return (
      <MessageGate key={"login"}>
        <div className={"login"}>
          <div className={"login__content-wrapper"}>
            <div className={"login__logo-wrapper"}>
              <SglIcon
                className={"login__logo"}
              />
            </div>

            <div className={"login__form-wrapper"}>
              <Form
                formItems={[
                  {
                    component: Input,
                    props: {
                      placeholder: "john@doe.com"
                    },
                    fieldName: "email",
                    label: i18nRepo.getWord("email"),
                    config: {
                      required: true,
                      message: i18nRepo.getError("email_is_required")
                    }
                  },
                  {
                    component: Input,
                    props: {
                      placeholder: "********",
                      type: "password"
                    },
                    fieldName: "password",
                    label: i18nRepo.getWord("password"),
                    config: {
                      required: true,
                      message: i18nRepo.getError("password_is_required")
                    }
                  }
                ]}
                ref={this.formRef}
                onSubmit={this.onSubmit}
              />
            </div>

            <div className={"login__button-wrapper"}>
              <Button
                onClick={this.onClickLogin}
                text={i18nRepo.getWord("login")}
              />
            </div>
          </div>
        </div>
      </MessageGate>
    );
  }
}

const mapDispatchToProps = (dispatch) => (
{
  setLoading: (loading) => dispatch(setLoading(loading))
}
);

export default withAuth(connect(null, mapDispatchToProps)(Login));