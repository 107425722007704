import React, {Component} from "react";

import i18nRepo from "../../i18n-repo/i18nRepo";
import CommonHelper from "../../libs/CommonHelper";

import {message} from "./MessageGate";
import Button from "./Button";

const ONE_MB = 1048576; // bytes

class Upload extends Component
{
  constructor (props)
  {
    super(props);

    this.fileInputRef = React.createRef();
    this.fileReader = new FileReader();

    this.state = {
      base64: null,
      fileName: null
    };
  }

  setValue = (value) =>
  {
    this.setState((previousState, props) => ({
      base64: null,
      fileName: value
    }));
  };

  getValue = () =>
  {
    if (CommonHelper.isExist(this.state.base64))
    {
      return this.state.base64;
    }
    else
    {
      return this.state.fileName;
    }
  };

  onChangeFile = (event) =>
  {
    if (!CommonHelper.isExist(event.target.files[0]))
    {
      return;
    }

    const file = event.target.files[0];
    const fileName = file.name;

    if (file.size > ONE_MB)
    {
      message({
                type: "error",
                content: i18nRepo.getError("file_size_too_big")
              });

      return;
    }

    if (CommonHelper.isExist(this.fileInputRef.current))
    {
      this.fileReader.addEventListener("load", (event) =>
      {
        this.fileInputRef.current.src = event.target.result;
        this.setState(() => ({
          base64: event.target.result.split("base64,")[1]
        }));
      });

      this.fileReader.readAsDataURL(file);
    }

    this.setState((previousState, props) => ({
      fileName: fileName
    }));
  };

  onClickHandleUpload = (event) =>
  {
    event.preventDefault();

    if (CommonHelper.isExist(this.fileInputRef.current))
    {
      this.fileInputRef.current.click();
    }
  };

  onClickRemoveFile = () =>
  {
    this.setState((previousState, props) => ({
      base64: null,
      fileName: null
    }));
  };

  render ()
  {
    return (
      <div className={"upload__container"}>
        <input
          type={"file"}
          className={"upload__input"}
          accept={".jpeg, .jpg, .png"}
          onChange={this.onChangeFile}
          ref={this.fileInputRef}
        />

        <Button
          className={"upload__button"}
          text={(
            <div className={"upload__button-text-container"}>
              <i className={"fas fa-upload upload__button-icon"} />

              <div className={"upload__button-text"}>
                {
                  i18nRepo.getWord("upload")
                }
              </div>
            </div>
          )}
          onClick={this.onClickHandleUpload}
        />

        {
          CommonHelper.isExist(this.state.fileName)
          &&
          (
            <div className={"upload__file-container"}>
              <div className={"upload__file-name"}>
                {
                  this.state.fileName
                }
              </div>

              <i
                className={"fas fa-times upload__times-icon"}
                onClick={this.onClickRemoveFile}
              />
            </div>
          )
        }
      </div>
    );
  }
}

export default Upload;