import {
  SET_LANGUAGE,
  SET_LOADING,
  SET_WINDOW_STATUS,
  SET_SCROLL_DISABLED, SET_USER
} from "../actions/actionTypes";

import {initialLanguage} from "../../config/app.config";

import i18nRepo from "../../i18n-repo/i18nRepo";

const phoneBreakPoint = 560; // px
const tabletBreakPoint = 768; // px
const desktopBreakPoint = 1024; // px

const initialState = {
  language: initialLanguage,
  phoneBreakPoint: phoneBreakPoint,
  tabletBreakPoint: tabletBreakPoint,
  desktopBreakPoint: desktopBreakPoint,
  windowPreferences: {
    isMobile: window.innerWidth <= phoneBreakPoint,
    isTablet: window.innerWidth <= tabletBreakPoint,
    isDesktop: window.innerWidth <= desktopBreakPoint
  },
  loading: false,
  loadCounter: 0,
  user: null,
  scrollDisabled: false
};

const appReducer = (currentState = initialState, action) =>
{
  switch (action.type)
  {
    case SET_LANGUAGE:
      i18nRepo.setLanguage(action.payload.language);

      return {
        ...currentState,
        language: action.payload.language
      };

    case SET_LOADING:
      let currentCount = currentState.loadCounter;

      if (action.payload.loading)
      {
        currentCount = currentCount + 1;
      }
      else
      {
        currentCount = currentCount > 0
                       ? currentCount - 1
                       : 0;
      }

      return {
        ...currentState,
        currentCount: currentState.loadCounter,
        loading: currentCount !== 0
      };

    case SET_WINDOW_STATUS:
      return {
        ...currentState,
        windowPreferences: action.payload.windowPreferences
      };

    case SET_SCROLL_DISABLED:
      return {
        ...currentState,
        scrollDisabled: action.payload.scrollDisabled
      };

    case SET_USER:
      return {
        ...currentState,
        user: action.payload.user
      };

    default:
      return currentState;
  }
};

export default appReducer;