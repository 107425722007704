import React, {Component} from "react";
import parse from "html-react-parser";

import i18nRepo from "../../i18n-repo/i18nRepo";

import Button from "../ui-kit/Button";
import {connect} from "react-redux";
import {setLoading} from "../../store/actions/creators/appActionCreator";
import BlogService from "../../services/BlogService";
import withAuth from "../high-order-components/Auth-HOC";
import {message} from "../ui-kit/MessageGate";
import CommonHelper from "../../libs/CommonHelper";
import moment from "moment";
import {API_URL} from "../../config/server.config";

class Blog extends Component
{
  constructor (props)
  {
    super(props);

    this.service = new BlogService();

    this.state = {
      posts: []
    };
  }

  async componentDidMount ()
  {
    try
    {
      this.props.setLoading(true);
      const posts = (await this.service.get({}, this.props.language)).documents;

      this.setState((previousState, props) => ({
        posts: posts
      }));
    }
    catch (error)
    {
      if (error.statusCode === 401)
      {
        this.props.authActions.logout();
      }

      message({
                type: "error",
                content: i18nRepo.getError("there_is_a_problem_with_getting_your_posts")
              });
    }
    finally
    {
      this.props.setLoading(false);
    }
  }

  onClickAdd = () =>
  {
    this.props.history.push("/add-blog");
  };

  onClickPost = (_post) =>
  {
    this.props.history.push({
                              pathname: "/show-blog",
                              state: {
                                _id: _post
                              }
                            });
  };

  render ()
  {
    return (
      <div className={"initial-page blog"}>
        <div className={"blog__header"}>
          <div className={"blog__header-text"}>
            {
              i18nRepo.getWord("blog")
            }
          </div>

          <div className={"blog__header-button"}>
            <Button
              text={i18nRepo.getWord("add")}
              onClick={this.onClickAdd}
            />
          </div>
        </div>

        <div className={"blog__content-wrapper"}>
          {
            CommonHelper.isExist(this.state.posts)
            &&
            (
              this.state.posts.map((post, index) => (
                <div
                  onClick={() => this.onClickPost(post._id)}
                  key={index}
                  className={"blog__content"}
                >
                  {
                    !this.props.windowPreferences.isTablet
                    &&
                    (
                      <div className={"blog__content-left"}>
                        <img
                          className={"blog__content-image"}
                          src={API_URL + "/static/uploads/" + post.picture}
                        />
                      </div>
                    )
                  }

                  <div className={"blog__content-right"}>
                    <div className={"blog__content-top"}>
                      <div className={"blog__content-locale"}>
                        <div className={"blog__content-locale-text"}>
                          {
                            i18nRepo.getWord("language")
                          }
                        </div>

                        <div className={"blog__content-locale-value"}>
                          {
                            post.language
                          }
                        </div>
                      </div>

                      <div className={"blog__content-creation"}>
                        {
                          moment(post.createdAt).format("DD-MM-YYYY")
                        }
                      </div>
                    </div>

                    <div className={"blog__content-bottom"}>
                      <div className={"blog__content-subject"}>
                        {
                          post.subject
                        }
                      </div>

                      <div className={"blog__content-content"}>
                        {
                          parse(post.content)
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  windowPreferences: state.appReducer.windowPreferences
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading))
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Blog));