import React, {Component} from "react";
import _ from "lodash";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";

import CommonHelper from "../../libs/CommonHelper";

class QuillEditor extends Component
{
  constructor (props)
  {
    super(props);

    this.quillRef = React.createRef();

    this.modules = {
      toolbar: [
      ]
    };

    if (!props.minimum)
    {
      this.modules.toolbar = [
        ...this.modules.toolbar,
        [{"list": "ordered"}, {"list": "bullet"}],
        ['bold', 'italic', 'underline'],
        [{"indent": "-1"}, {"indent": "+1"}],          // outdent/indent

        [{"size": ["small", false, "large", "huge"]}],  // custom dropdown
        [{"align": []}]
      ];
    }

    this.state = {
      content: CommonHelper.isInitialized(this.props.value)
               ? DOMPurify.sanitize(this.props.value)
               : "",
      key: "a"
    };
  }

  componentDidMount ()
  {
    if (CommonHelper.isInitialized(this.props.value))
    {
      this.setState((previousState, props) => ({
        content: this.props.value,
        key: _.isEqual(previousState.key, "a")
             ? "b"
             : "a"
      }));
    }
  }

  shouldComponentUpdate (nextProps, nextState, nextContext)
  {
    if (!_.isEqual(this.props.value, nextProps.value))
    {
      this.setState((previousState, props) => ({
        content: nextProps.value,
        key: _.isEqual(previousState.key, "a")
             ? "b"
             : "a"
      }));
    }

    return true;
  }

  _asyncSetState = (callback) =>
  {
    return new Promise((resolve, reject) =>
                       {
                         this.setState(callback);
                         resolve();
                       });
  };

  setValue = (value) =>
  {
    const HTML = DOMPurify.sanitize(value);

    this.setState((previousState, props) => ({
      content: HTML,
      key: _.isEqual(previousState.key, "a")
           ? "b"
           : "a"

    }));
  };

  getValue = () =>
  {
    if (!CommonHelper.isExist(this.state.content))
    {
      return null;
    }

    return DOMPurify.sanitize(this.state.content);
  };

  onChangeQuill = (value, delta, source, editor) =>
  {
    const purifiedValue = DOMPurify.sanitize(value);

    if (CommonHelper.isExist(this.props.onChange))
    {
      this.props.onChange(purifiedValue);
    }

    this.setState((previousState, props) => ({
      content: purifiedValue
    }));

    if (CommonHelper.isExist(this.quillRef.current))
    {
      this.quillRef.current.focus();
    }
  };

  render ()
  {
    return (
      <div
        className={"quill-editor"}
        key={this.state.key}
      >
        <ReactQuill
          ref={this.quillRef}
          defaultValue={this.state.content}
          onChange={(content, delta, source, editor) =>
          {
            if (_.isEqual(source, "user"))
            {
              this.onChangeQuill(content, delta, source, editor);
            }
          }}
          theme="snow"
          modules={this.modules}
        />
      </div>
    );
  }
}

export default QuillEditor;