import React, {Component} from "react";
import _ from "lodash";

import RouteHelper from "../libs/RouteHelper";
import i18nRepo from "../i18n-repo/i18nRepo";

import Home from "../components/containers/Home";
import withAuth from "../components/high-order-components/Auth-HOC";
import Login from "../components/containers/Login";
import Contact from "../components/containers/Contact";
import Blog from "../components/containers/Blog";
import AddBlog from "../components/containers/AddBlog";
import BlogContact from "../components/containers/BlogContact";
import ShowBlog from "../components/containers/ShowBlog";

class Router extends Component
{
  constructor (props)
  {
    super(props);

    i18nRepo.setLanguage(props.language);

    this.routeMap = {
      routes: [
        RouteHelper.generateRoute("/", {
          key: "2",
          exact: true,
          strict: false,
          push: true
        }, false, Home),
        RouteHelper.generateRoute("/contact", {
          key: "2",
          exact: true,
          strict: false,
          push: true
        }, true, Contact, Home),
        RouteHelper.generateRoute("/blog", {
          key: "2",
          exact: true,
          strict: false,
          push: true
        }, true, Blog, Home),
        RouteHelper.generateRoute("/blog-contact", {
          key: "2",
          exact: true,
          strict: false,
          push: true
        }, true, BlogContact, Home),
        RouteHelper.generateRoute("/add-blog", {
          key: "2",
          exact: true,
          strict: false,
          push: true
        }, true, AddBlog, Home),
        RouteHelper.generateRoute("/show-blog", {
          key: "2",
          exact: true,
          strict: false,
          push: true
        }, true, ShowBlog, Home),
        RouteHelper.generateRoute("/login", {
          key: "1",
          exact: true,
          strict: false,
          push: true
        }, false, Login)
      ],
      redirectRoute: "/"
    };

    this.state = {
      authenticated: false
    };
  };

  componentDidMount ()
  {
    const authenticated = this.props.authActions.authenticate();

    this.setState((previousState, props) => ({
      authenticated: authenticated
    }));

    if (!authenticated)
    {
      this.props.authActions.logout();
    }
  }

  shouldComponentUpdate (nextProps, nextState, nextContent)
  {
    if (!_.isEqual(this.props.location.pathname, nextProps.location.pathname))
    {
      window.scrollTo(0, 0);
    }

    const authenticated = this.props.authActions.authenticate();

    if (nextState.authenticated !== authenticated)
    {
      this.setState((previousState, props) => ({
        authenticated: authenticated
      }));

      if (!authenticated)
      {
        this.props.authActions.logout();
      }
    }

    return true;
  }

  render ()
  {
    return RouteHelper.renderRoute(this.routeMap);
  }
}

export default withAuth(Router);