export const wordRepoEN = {
  add: "Add",

  blog: "Blog",
  blog_contact: "Blog contact",

  cancel: "Cancel",
  contact: "Contact",
  content: "Content",
  createdAt: "Created at:",

  delete: "Delete",

  email: "E-mail",

  language: "Language",
  login: "Login",
  logout: "Logout",

  menu: "Menu",

  name: "Name",

  ok: "Ok",

  password: "Password",
  phone: "Phone",
  picture: "Picture",

  subject: "Subject",

  update: "Update",
  upload: "Upload"
};