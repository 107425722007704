import Service from "./Service";
import AdminService from "./AdminService";

const route = "/posts";

class BlogService extends AdminService
{
  constructor ()
  {
    super(route, {
      defaultRoutes: [
        Service.ROUTE_MAP.GET,
        Service.ROUTE_MAP.GET_BY_ID,
        Service.ROUTE_MAP.CREATE,
        Service.ROUTE_MAP.UPDATE_BY_ID,
        Service.ROUTE_MAP.DELETE_BY_ID
      ],
      authenticated: true
    });
  }
}

export default BlogService;