import React, {Component} from "react";
import {connect} from "react-redux";
import _ from "lodash";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";

import {setWindowStatus} from "./store/actions/creators/appActionCreator";

import CommonHelper from "./libs/CommonHelper";

import Router from "./router/Router";

import Loader from "./components/ui-kit/Loader";

class App extends Component
{
  constructor (props)
  {
    super(props);

    this.appRef = React.createRef();

    this.state = {
      innerHeight: window.innerHeight
    };
  }

  shouldComponentUpdate (nextProps, nextState, nextContext)
  {
    if (!this.props.scrollDisabled && nextProps.scrollDisabled)
    {
      disableBodyScroll(this.appRef.current);
    }

    if (this.props.scrollDisabled && !nextProps.scrollDisabled)
    {
      enableBodyScroll(this.appRef.current);
    }

    return true;
  }

  componentDidMount ()
  {
    window.addEventListener("resize", this.onWindowResize);
  }

  componentWillUnmount ()
  {
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () =>
  {
    if ((this.props.windowPreferences.isMobile !== (window.innerWidth <= this.props.phoneBreakPoint)) ||
        (this.props.windowPreferences.isTablet !== (window.innerWidth <= this.props.tabletBreakPoint)) ||
        (this.props.windowPreferences.isDesktop !== (window.innerWidth <= this.props.desktopBreakPoint)))
    {
      this.props.setWindowStatus({
                                   isMobile: window.innerWidth <= this.props.phoneBreakPoint,
                                   isTablet: window.innerWidth <= this.props.tabletBreakPoint,
                                   isDesktop: window.innerWidth <= this.props.desktopBreakPoint
                                 });
    }

    if (!_.isEqual(window.innerHeight, this.state.innerHeight))
    {
      this.setState((previousState, props) => ({
        innerHeight: window.innerHeight
      }));
    }
  };

  render ()
  {
    return (
      <Loader loading={this.props.loading}>
        <div
          ref={this.appRef}
          key={this.props.language}
          lang={this.props.language}
          style={{
            width: `calc(100vw - 0.5rem)`, // 0.5 rem is the width of scroll if it is changed you should also change this value
            marginTop: `calc(100vh - ${CommonHelper.isExist(this.state.innerHeight
                                                            ? this.state.innerHeight + "px"
                                                            : "100vh")})`
          }}
        >
          <Router />
        </div>
      </Loader>
    );
  };
}

const mapStateToProps = (state) => ({
  windowPreferences: state.appReducer.windowPreferences,
  phoneBreakPoint: state.appReducer.phoneBreakPoint,
  tabletBreakPoint: state.appReducer.tabletBreakPoint,
  desktopBreakPoint: state.appReducer.desktopBreakPoint,
  loading: state.appReducer.loading,
  language: state.appReducer.language,
  scrollDisabled: state.appReducer.scrollDisabled
});

const mapDispatchToProps = (dispatch) => ({
  setWindowStatus: (isMobile) => dispatch(setWindowStatus(isMobile))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
