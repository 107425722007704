import Service from "./Service";

class AdminService extends Service
{
  constructor (route, options)
  {
    super("/admin" + route, options);
  }
}

export default AdminService;