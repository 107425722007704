import React, {Component} from "react";
import Form from "../ui-kit/Form";
import Select from "../ui-kit/Select";
import i18nRepo from "../../i18n-repo/i18nRepo";
import Input from "../ui-kit/Input";
import QuillEditor from "../ui-kit/QuillEditor";
import Upload from "../ui-kit/Upload";

class AddBlogForm extends Component
{
  render ()
  {
    const prepareLanguageOptions = () =>
    {
      const result = [];

      const languageKeys = Object.keys(i18nRepo.languages);
      for (let i = 0; i < languageKeys.length; i++)
      {
        const currentKey = languageKeys[i];
        const currentLanguage = i18nRepo.languages[currentKey];
        const currentLanguageDisplay = i18nRepo.languageDisplays[currentKey];

        result.push({
                      displayName: currentLanguageDisplay,
                      value: currentLanguage,
                      key: currentKey
                    });
      }

      return result;
    };

    return (
      <Form
        formItems={[
          {
            component: Upload,
            props: {},
            fieldName: "picture",
            label: i18nRepo.getWord("picture"),
            config: {}
          },
          {
            component: Select,
            props: {
              options: prepareLanguageOptions()
            },
            fieldName: "language",
            label: i18nRepo.getWord("language"),
            config: {
              required: true,
              message: i18nRepo.getError("language_is_required")
            }
          },
          {
            component: Input,
            props: {
              placeholder: i18nRepo.getWord("subject")
            },
            fieldName: "subject",
            label: i18nRepo.getWord("subject"),
            config: {
              required: true,
              message: i18nRepo.getError("subject_is_required")
            }
          },
          {
            component: QuillEditor,
            props: {
              placeholder: i18nRepo.getWord("content")
            },
            fieldName: "content",
            label: i18nRepo.getWord("content"),
            config: {
              required: true,
              message: i18nRepo.getError("content_is_required")
            }
          }
        ]}
        onSubmit={this.props.onSubmit}
        ref={this.props.formRef}
      />
    );
  }
}

export default AddBlogForm;