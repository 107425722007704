export const wordRepoTR = {
  add: "Ekle",

  blog: "Blog",
  blog_contact: "Blog iletişimi",

  cancel: "İptal",
  contact: "İletişim",
  content: "İçerik",
  createdAt: "Yaratıldı:",

  delete: "Sil",

  email: "E-posta",

  language: "Dil",
  login: "Giriş",
  logout: "Çıkış",

  menu: "Menü",

  name: "İsim",

  ok: "Tamam",

  password: "Şifre",
  phone: "Telefon",
  picture: "Resim",

  subject: "Konu",

  update: "Güncelle",
  upload: "Yükle"
};