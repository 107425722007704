export const sentenceRepoTR = {
  add_blog: "Blog ekle",
  are_you_sure_you_want_to_delete_this_post: "Bu gönderiyi silmek istediğine emin misin?",

  show_blog: "Blog göster",

  we_successfully_added_this_post: "Bu gönderiyi başarıyla ekledik.",

  you_have_successfully_deleted_post: "Gönderiyi başarıyla sildin.",
  you_have_successfully_updated_post: "Gönderiyi başarıyla güncelledin."
};