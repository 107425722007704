export const sentenceRepoEN = {
  add_blog: "Add blog",
  are_you_sure_you_want_to_delete_this_post: "Are you sure you want to delete this post?",

  show_blog: "Show blog",
  
  we_successfully_added_this_post: "We successfully added this post.",

  you_have_successfully_deleted_post: "You have successfully deleted post.",
  you_have_successfully_updated_post: "You have successfully updated post."
};