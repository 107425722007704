import Service from "./Service";

import {axiosInstance} from "../config/server.config";

const route = "/auth/admin";

class AuthService extends Service
{
  constructor ()
  {
    super(route, {defaultRoutes: [], authenticated: false});
  }

  login = async (body, language) =>
  {
    try
    {
      const config = this._generateConfig(language);

      const response = await axiosInstance.post(this._prepareRoute() + "/login", body, config);
      return this._extractDataFromSuccess(response);
    }
    catch (error)
    {
      throw this._extractDataFromError(error);
    }
  };
}

export default AuthService;