import Service from "./Service";
import AdminService from "./AdminService";

const route = "/postContactData";

class PostContactService extends AdminService
{
  constructor ()
  {
    super(route, {
      defaultRoutes: [
        Service.ROUTE_MAP.GET
      ],
      authenticated: true
    });
  }
}

export default PostContactService;