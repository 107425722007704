export const errorRepoEN = {
  content_is_required: "Content is required.",

  email_is_required: "E-mail is required.",

  file_size_too_big: "File size too big.",

  language_is_required: "Language.is required.",

  password_is_required: "Password is required.",

  subject_is_required: "Subject is required.",

  there_is_a_problem_with_deleting_your_post: "There is a problem with deleting your post.",
  there_is_a_problem_with_getting_your_post: "There is a problem with getting your post.",
  there_is_a_problem_with_getting_your_posts: "There is a problem with getting your posts.",
  there_is_a_problem_with_getting_your_contact_data: "There is a problem with getting your contact data.",
  there_is_a_problem_with_update_post_operation: "There is a problem with update post operation.",

  we_could_not_add_this_post: "We could not add this post.",
};