export const errorRepoTR = {
  content_is_required: "İçerik gerekli bir alan.",

  email_is_required: "E-posta gerekli bir alan.",

  file_size_too_big: "Dosya çok büyük.",

  language_is_required: "Dil gerekli bir alan.",

  password_is_required: "Şifre gerekli bir alan.",

  subject_is_required: "Konu gerekli bir alan.",

  there_is_a_problem_with_deleting_your_post: "Gönderiyi silerken bir sorunla karşılaştık.",
  there_is_a_problem_with_getting_your_post: "Gönderiyi getirirken bir problem oluştu.",
  there_is_a_problem_with_getting_your_posts: "Gönderilerini getirirken bir problem oluştu.",
  there_is_a_problem_with_getting_your_contact_data: "İletişim bilgilerini getirirken bir sorunla karşılaştık.",
  there_is_a_problem_with_update_post_operation: "Gönderiyi güncellemeyle alakalı bir sorun var.",

  we_could_not_add_this_post: "Bu gönderiyi ekleyemedik."
};