import React, {Component} from "react";
import CommonHelper from "../../libs/CommonHelper";
import {setLoading} from "../../store/actions/creators/appActionCreator";
import withAuth from "../high-order-components/Auth-HOC";
import {connect} from "react-redux";
import {confirm, message} from "../ui-kit/MessageGate";
import i18nRepo from "../../i18n-repo/i18nRepo";
import BlogService from "../../services/BlogService";
import AddBlogForm from "../elements/AddBlogForm";
import Button from "../ui-kit/Button";
import isBase64 from "validator/es/lib/isBase64";

class ShowBlog extends Component
{
  constructor (props)
  {
    super(props);

    this.service = new BlogService();

    this.formRef = React.createRef();

    this.state = {
      document: null
    };
  }

  async componentDidMount ()
  {
    try
    {
      this.props.setLoading(true);

      if (!CommonHelper.isExist(this.props.location.state) || !CommonHelper.isExist(this.props.location.state._id))
      {
        this.props.history.replace("/blog");
      }

      const {document} = (await this.service.getByID(this.props.location.state._id, this.props.language));

      this.formRef.current.setFieldValues({
                                            picture: document.picture,
                                            language: document.language,
                                            subject: document.subject,
                                            content: document.content
                                          });
      this.setState((previousState, props) => ({
        document: document
      }));
    }
    catch (error)
    {
      if (error.statusCode === 401)
      {
        this.props.authActions.logout();
      }

      message({
                type: "error",
                content: i18nRepo.getError("there_is_a_problem_with_getting_your_contact_data")
              });
    }
    finally
    {
      this.props.setLoading(false);
    }
  }

  onSubmit = async (values) =>
  {
    try
    {
      this.props.setLoading(true);

      if (CommonHelper.isExist(values.picture) && !isBase64(values.picture))
      {
        delete values.picture;
      }

      const {document} = await this.service.updateByID(this.state.document._id, this.state.document.version, values, this.props.language);

      console.log(document);
      message({
                type: "success",
                content: i18nRepo.getSentence("you_have_successfully_updated_post")
              });

      this.setState((previousState, props) => ({
        document: document
      }));
    }
    catch (error)
    {
      console.log(error);
      if (error.statusCode === 401)
      {
        this.props.authActions.logout();
      }

      message({
                type: "error",
                content: i18nRepo.getError("there_is_a_problem_with_update_post_operation")
              });
    }
    finally
    {
      this.props.setLoading(false);
    }
  };

  onClickUpdate = () =>
  {
    this.formRef.current.submit();
  };

  deleteItem = async () =>
  {
    try
    {
      this.props.setLoading(true);

      await this.service.deleteByID(this.state.document._id, this.state.document.version, this.props.language);

      message({
                type: "success",
                content: i18nRepo.getSentence("you_have_successfully_deleted_post")
              });

      this.props.history.push("/blog");
    }
    catch (error)
    {
      console.log(error);
      if (error.statusCode === 401)
      {
        this.props.authActions.logout();
      }

      message({
                type: "error",
                content: i18nRepo.getError("there_is_a_problem_with_deleting_your_post")
              });
    }
    finally
    {
      this.props.setLoading(false);
    }
  };

  onClickDelete = () =>
  {
    confirm({
              text: i18nRepo.getSentence("are_you_sure_you_want_to_delete_this_post"),
              onOk: this.deleteItem
            });
  };

  render ()
  {
    return (
      <div className={"initial-page show-blog"}>
        <div className={"show-blog__content-wrapper"}>
          <div className={"show-blog__header-wrapper"}>
            {
              i18nRepo.getSentence("show_blog")
            }
          </div>

          <div className={"show-blog__form-wrapper"}>
            <AddBlogForm
              onSubmit={this.onSubmit}
              formRef={this.formRef}
            />
          </div>

          <div className={"show-blog__button-wrapper"}>
            <Button
              text={i18nRepo.getWord("update")}
              onClick={this.onClickUpdate}
            />

            <Button
              className={"show-blog__delete-button"}
              text={i18nRepo.getWord("delete")}
              onClick={this.onClickDelete}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading))
});

export default withAuth(connect(null, mapDispatchToProps)(ShowBlog));